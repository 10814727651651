import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from 'store/actions';
import IconRefresh from 'shared/img/icons/refresh.svg';
import './Lock.scss';

class Lock extends Component {

  onClickHandler = () => {
    this.props.gameStart();
    this.props.countdownReset();
  }

  render() {
    let lock = null;
    if( this.props.canPlay === false ){
      lock = (
        <div className="Lock-wrapper">
          <div className="Lock" onClick={this.onClickHandler}>
            <div className="Lock__content">
              <img src={IconRefresh} height="100" alt="Refresh" />
            </div>
          </div>
        </div>
      )
    }
    return(
      lock
    )
  }
}

const mapStateToProps = state => {
  return {
    canPlay: state.canPlay
  }
}

const mapDispatchToProps = dispatch => {
  return {
    gameStart: () => dispatch({type: actionTypes.GAME_START}),
    countdownReset: () => dispatch({type: actionTypes.COUNTDOWN_RESET}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Lock);