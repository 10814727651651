import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from 'store/actions';
import Filler from './Filler';
import './Countdown.scss';

class Countdown extends Component{
  state = {
    intervalId: 0,
  }

  tick = () => {
    if( this.props.canPlay && this.props.canCountdown ){
      let currentTime = this.props.currentTime;
      if( currentTime > 0 ){
        currentTime = currentTime - 1;
        this.props.currentTimeMinus();
      }
      else{
        this.props.gameStop();
      }
    }
  }

  componentDidMount(){
     var intervalId = setInterval(this.tick, 1000);
     // store intervalId in the state so it can be accessed later:
     this.setState({intervalId: intervalId});
  }

  componentWillUnmount() {
     // use intervalId from the state to clear the interval
     clearInterval(this.state.intervalId);
  }

  render(){
    let countdown = null;
    if( this.props.countdown ){
      countdown = (
        <div className="Coundown-wrapper">
          <div className="Coundown">
            <Filler />
          </div>
        </div>
      )
    }

    return(
      countdown
    );
  }
}

const mapStateToProps = state => {
  return {
    canPlay: state.canPlay,
    canCountdown: state.canCountdown,
    countdown: state.countdown,
    time: state.time,
    currentTime: state.currentTime
  }
}

const mapDispatchToProps = dispatch => {
  return {
    gameStart: () => dispatch({type: actionTypes.GAME_START}),
    gameStop: () => dispatch({type: actionTypes.GAME_STOP}),
    currentTimeMinus: () => dispatch({type: actionTypes.CURRENTTIME_MINUS})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Countdown);
