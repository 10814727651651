export const SWITCH_LANG = 'SWITCH_LANG';

export const GAME_START = 'GAME_START';
export const GAME_STOP = 'GAME_STOP';

export const COUNTDOWN_ENABLE = 'COUNTDOWN_ENABLE';
export const COUNTDOWN_DISABLE = 'COUNTDOWN_DISABLE';
export const COUNTDOWN_START = 'COUNTDOWN_START';
export const COUNTDOWN_STOP = 'COUNTDOWN_STOP';
export const COUNTDOWN_RESET = 'COUNTDOWN_RESET';

export const CURRENTTIME_MINUS = 'CURRENTTIME_MINUS';