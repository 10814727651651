import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import GUI from 'config/GUI';
import FlagCS from 'shared/img/flags/cs.svg';
import FlagEN from 'shared/img/flags/en.svg';
import FlagES from 'shared/img/flags/es.svg';
import FlagDE from 'shared/img/flags/de.svg';
import FlagFR from 'shared/img/flags/fr.svg';
import FlagPL from 'shared/img/flags/pl.svg';
import './Langs.scss';

const Langs = (props) => {
  return(
    <div className="langs-wrapper">
      <div className="langs">
        <NavLink exact className="langs-item" activeClassName="active" to="/" data-tip={GUI.translate.cs.name}><div className="langs-flag"><img alt="{FlagCS}" src={FlagCS} height="40" /></div></NavLink>
        <NavLink exact className="langs-item" activeClassName="active" to="/en" data-tip={GUI.translate.en.name}><div className="langs-flag"><img alt="{FlagEN}" src={FlagEN} height="40" /></div></NavLink>
        <NavLink exact className="langs-item" activeClassName="active" to="/de" data-tip={GUI.translate.de.name}><div className="langs-flag"><img alt="{FlagDE}" src={FlagDE} height="40" /></div></NavLink>
        <NavLink exact className="langs-item" activeClassName="active" to="/es" data-tip={GUI.translate.es.name}><div className="langs-flag"><img alt="{FlagES}" src={FlagES} height="40" /></div></NavLink>
        <NavLink exact className="langs-item" activeClassName="active" to="/fr" data-tip={GUI.translate.fr.name}><div className="langs-flag"><img alt="{FlagFR}" src={FlagFR} height="40" /></div></NavLink>
        <NavLink exact className="langs-item" activeClassName="active" to="/pl" data-tip={GUI.translate.pl.name}><div className="langs-flag"><img alt="{FlagPL}" src={FlagPL} height="40" /></div></NavLink>
      </div>
      <ReactTooltip place="bottom" type="dark" effect="solid"/>
    </div>
  )
}

export default Langs;