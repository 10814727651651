import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Cards from "containers/Cards/Cards";
import './App.scss';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route exact path="/" render={(props) => (<Cards {...props} lang='cs' />)} />
            <Route exact path="/en" render={(props) => (<Cards {...props} lang='en' />)} />
            <Route exact path="/de" render={(props) => (<Cards {...props} lang='de' />)} />
            <Route exact path="/es" render={(props) => (<Cards {...props} lang='es' />)} />
            <Route exact path="/fr" render={(props) => (<Cards {...props} lang='fr' />)} />
            <Route exact path="/pl" render={(props) => (<Cards {...props} lang='pl' />)} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
