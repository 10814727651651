class GUI{
  static default = {
    version: "2.5",
    lang: "cs"
  };

  static translate = {
    cs: {
      code: "cs-CZ",
      name: "Čeština",
      teams: "Týmová hra",
      team: "Tým",
      player: "Hráč",
      settings: "Nastavení",
      languages: "Jazyky"
    },
    en: {
      code: "en-US",
      name: "English",
      teams: "Team play",
      team: "Team",
      player: "Player",
      settings: "Settings",
      languages: "Languages"
    },
    es: {
      code: "es-ES",
      name: "Español",
      teams: "Juego de equipo",
      team: "Equipo",
      player: "Jugador",
      settings: "Ajustes",
      languages: "Idiomas"
    },
    de: {
      code: "de-DE",
      name: "Deutsch",
      teams: "Mannschaftsspiel",
      team: "Mannschaft",
      player: "Spieler",
      settings: "Einstellungen",
      languages: "Sprachen"
    },
    fr: {
      code: "fr-FR",
      name: "Le français",
      teams: "Jeu d'équipe",
      team: "Équipe",
      player: "Joueur",
      settings: "Paramètres",
      languages: "Les langues"
    },
    pl: {
      code: "pl-PL",
      name: "Polski",
      teams: "Gra zespołowa",
      team: "Zespół",
      player: "Gracz",
      settings: "Ustawienia",
      languages: "Języki"
    },
  }
}

export default GUI;