import React from 'react';
import GUI from 'config/GUI';
import './Copyright.scss';

const Copyright = () => {
  return(
    <div className="copyright-wrapper">
      <div className="copyright">
        <p><a href="https://www.listeckova-hra.cz">Lístečková hra</a> v{GUI.default.version} - <a href="https://www.nase-trida.cz/listeckova-hra-webova-aplikace/" target="_blank" rel="noopener noreferrer">Pravidla hry</a></p>
        <p>
          2018 - 2019 &copy; <a href="https://www.musiol.cz" target="_blank" rel="noopener noreferrer">Tomáš Musiol</a> <br />
          2018 - 2019 &copy; <a href="https://www.nase-trida.cz" target="_blank" rel="noopener noreferrer">Naše třída</a>
        </p>
        <p>Icons made by <a href="https://www.freepik.com/" title="Freepik" target="_blank" rel="noopener noreferrer">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noopener noreferrer">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">CC 3.0 BY</a></p>
      </div>
    </div>
  )
}

export default Copyright;