import React, { Component } from 'react';
import * as actionTypes from 'store/actions';
import Countdown from 'components/Countdown/Countdown';
import Lock from 'components/Lock/Lock';
import Menu from 'components/Menu/Menu';
import Char from 'components/Char/Char';
import Category from 'components/Category/Category';
import './Cards.scss';

class Cards extends Component{
  render() {
    return(
      <div className="Cards-wrapper">
        <Menu />
        <Countdown />
        <div className="Cards">
          <Char />
          <Category />
        </div>
        <Lock />
      </div>
    )
  }
}

export default Cards;
