class Config{
  static game = {
    chars: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
    categories: [
      { cs: 'sportovec', en: 'athlete', es: 'atleta', de: 'Sportler', fr: 'athlète', pl: 'sportowiec'},
      { cs: 'hlavní město', en: 'Capital', es: 'capital', de: 'Hauptstadt', fr: 'capital', pl: 'kapitał'},
      { cs: 'stát', en: 'state', es: 'país', de: 'Staat', fr: 'pays', pl: 'kraj'},
      { cs: 'politik', en: 'politician', es: 'político', de: 'Politiker/Politikerin', fr: 'homme politique', pl: 'polityk'},
      { cs: 'spisovatel', en: 'writer', es: 'escritor', de: 'Schriftsteller/Schriftstellerin', fr: 'écrivain', pl: 'pisarz'},
      { cs: 'řeka', en: 'river', es: 'río', de: 'Fluss', fr: 'rivière', pl: 'rzeka'},
      { cs: 'hora', en: 'mountain', es: 'montaña', de: 'Berg', fr: 'montagne', pl: 'góra'},
      { cs: 'učitel', en: 'teacher', es: 'maestro', de: 'Lehrer/Lehrerin', fr: 'professeur', pl: 'nauczyciel'},
      { cs: 'školní pomůcka', en: 'school supplies', es: 'objetos de clase', de: 'Schulsache', fr: 'fournitures scolaires', pl: 'szkolne'},
      { cs: 'místnost', en: 'room', es: 'habitación', de: 'Raum', fr: 'chambre', pl: 'pokój'},
      { cs: 'dopravní prostředek', en: 'means of transport', es: 'vehículo', de: 'Verkehrsmittel', fr: 'véhicule', pl: 'pojazd'},
      { cs: 'počasí', en: 'weather', es: 'tiempo atmosférico', de: 'Wetter ', fr: 'temps', pl: 'pogoda'},
      { cs: 'člen rodiny', en: 'family member', es: 'miembro de la familia', de: 'Familienmitglied', fr: 'membre de la famille', pl: 'członek rodziny'},
      { cs: 'potravina', en: 'foodstuff', es: 'comida', de: 'Lebensmittel', fr: 'nourriture', pl: 'żywność'},
      { cs: 'školní předmět', en: 'school subject', es: 'asignatura', de: 'Schulsache', fr: 'matière scolaire', pl: 'przedmiotem szkolnym'},
      { cs: 'skladatel', en: 'composer', es: 'compositor', de: 'Komponist/Komponistin', fr: 'compositeur', pl: 'kompozytor'},
      { cs: 'ovoce', en: 'fruit', es: 'fruta', de: 'Obst', fr: 'fruit', pl: 'owoc'},
      { cs: 'nábytek', en: 'furniture', es: 'muebles', de: 'Möbel', fr: 'meubles', pl: 'meble'},
      { cs: 'oblečení', en: 'clothes', es: 'ropa', de: 'Kleidung', fr: 'vêtements', pl: 'odzież'},
      { cs: 'vynálezce', en: 'inventor', es: 'inventor', de: 'Erfinder/Erfinderin', fr: 'inventeur', pl: 'wynalazca'},
      { cs: 'technologie', en: 'technology', es: 'tecnología', de: 'Technologie', fr: 'technologie', pl: 'technologia'},
      { cs: 'barva', en: 'color', es: 'color', de: 'Farbe', fr: 'couleur', pl: 'kolor'},
      { cs: 'film', en: 'film', es: 'película', de: 'Film', fr: 'film', pl: 'film'},
      { cs: 'pohádková postava', en: 'fairy-tale character', es: 'personaje de cuento de hadas', de: 'Märchengestalt', fr: 'caractère de conte de fées', pl: 'charakter bajki'},
      { cs: 'písnička', en: 'song', es: 'canción', de: 'Lied', fr: 'chanson', pl: 'piosenka'},
      { cs: 'zpěvák', en: 'singer', es: 'cantante', de: 'Sänger/Sängerin', fr: 'chanteur', pl: 'piosenkarz'},
      { cs: 'hmyz', en: 'insect', es: 'insectos', de: 'Insekt', fr: 'insectes', pl: 'owady'},
      { cs: 'savec', en: 'mammal', es: 'mamífero', de: 'Säugetier', fr: 'mammifère', pl: 'ssak'},
      { cs: 'pták', en: 'bird', es: 'pájaro', de: 'Vogel', fr: 'oiseau', pl: 'ptak'},
      { cs: 'plaz', en: 'beach', es: 'reptil', de: 'Reptil', fr: 'reptile', pl: 'gad'},
      { cs: 'část těla', en: 'a body part', es: 'parte del cuerpo', de: 'Körperteil', fr: 'une partie de corps', pl: 'część ciała'},
      { cs: 'sportovní vybavení', en: 'sport equipment', es: 'artículos deportivos', de: 'Sportausstattung', fr: 'équipements sportifs', pl: 'sprzęt sportowy'},
      { cs: 'sport', en: 'sport', es: 'deporte', de: 'Sportart ', fr: 'sport', pl: 'sport'},
      { cs: 'zelenina', en: 'vegetables', es: 'verdura', de: 'Gemüse', fr: 'légumes', pl: 'warzywa'},
      { cs: 'TV seriál', en: 'TV series', es: 'series de televisión', de: 'Fernsehserie', fr: 'Série TV', pl: 'serial'},
      { cs: 'den', en: 'day', es: 'día', de: 'Tag', fr: 'jour', pl: 'dzień'},
      { cs: 'mužské jméno', en: 'male name', es: 'nombre masculino', de: 'männlicher Name', fr: 'nom masculin', pl: 'male name'},
      { cs: 'ženské jméno', en: 'female name', es: 'nombre femenino', de: 'Frauenname', fr: 'nom féminin', pl: 'female name'},
      { cs: 'tradice', en: 'tradition', es: 'tradición', de: 'Tradition', fr: 'tradition', pl: 'tradycja'},
      { cs: 'nápoje', en: 'drinks', es: 'bebidas', de: 'Getränk', fr: 'boissons', pl: 'napoje'},
      { cs: 'pečivo', en: 'pastry', es: 'pasteles', de: 'Gebäck', fr: 'pâtisserie', pl: 'ciasto'},
      { cs: 'hračka', en: 'toy', es: 'juguete', de: 'Spielzeug', fr: 'jouet', pl: 'zabawka'},
      { cs: 'materiál', en: 'material', es: 'material', de: 'Material', fr: 'matière', pl: 'tworzywo'},
      { cs: 'koření', en: 'spice', es: 'condimento', de: 'Gewürz', fr: 'assaisonnement', pl: 'przyprawa'},
      { cs: 'zvíře', en: 'animal', es: 'animal', de: 'Tier', fr: 'bête', pl: 'bestia'},
      { cs: 'strom', en: 'tree', es: 'árbol', de: 'Baum', fr: 'arbre', pl: 'drzewo'},
      { cs: 'rostlina', en: 'plant', es: 'planta', de: 'Pflanze', fr: 'plante', pl: 'roślina'},
      { cs: 'veličina', en: 'quantity', es: 'cantidad', de: 'Größe (mat/phys)', fr: 'quantité', pl: 'ilość'},
      { cs: 'nemoc', en: 'disease', es: 'enfermedad', de: 'Krankheit', fr: 'maladie', pl: 'choroba'},
      { cs: 'zdravotní potřeby', en: 'health needs', es: 'remedios de medicina', de: 'Gesundheitspflegemittel', fr: 'besoins de santé', pl: 'potrzeby zdrowotne'},
      { cs: 'profese', en: 'profession', es: 'profesión', de: 'Beruf', fr: 'profession', pl: 'zawód'},
      { cs: 'lék', en: 'medicine', es: 'medicina', de: 'Heilmittel ', fr: 'médecine', pl: 'medycyna'},
      { cs: 'měna', en: 'currency', es: 'moneda', de: 'Währung', fr: 'monnaie', pl: 'waluta'},
      { cs: 'vlastnost', en: 'property', es: 'propiedad', de: 'Immobilien', fr: 'propriété', pl: 'nieruchomość'},
      { cs: 'koníček', en: 'hobby', es: 'pasatiempos', de: 'Hobby', fr: 'hobby', pl: 'hobby'},
      { cs: 'povolání', en: 'profession', es: 'profesión', de: 'Beruf', fr: 'profession', pl: 'zawód'},
      { cs: 'činnost', en: 'activity', es: 'actividad', de: 'Aktivität', fr: 'activité', pl: 'działalność'},
      { cs: 'nástroj', en: 'tool', es: 'herramienta', de: 'Werkzeug', fr: 'outil', pl: 'narzędzie'},
      { cs: 'chemický prvek', en: 'chemical element', es: 'elemento químico', de: 'chemisches Element', fr: 'élément chimique', pl: 'pierwiastek'},
      { cs: 'herec', en: 'actor', es: 'actor', de: 'Schauspieler', fr: 'acteur', pl: 'aktor'},
      { cs: 'počítačová hra', en: 'computer game', es: 'juego de ordenador', de: 'Computerspiel', fr: 'jeu informatique', pl: 'gra komputerowa'},
      { cs: 'komix', en: 'komix', es: 'Komix', de: 'komix', fr: 'komix', pl: 'Komix'},
      { cs: 'kniha', en: 'book', es: 'libro', de: 'Buch', fr: 'livre', pl: 'książka'},
      { cs: 'skupina', en: 'group', es: 'grupo', de: 'Gruppe', fr: 'groupe', pl: 'grupa'},
      { cs: 'Marvel (postava)', en: 'Marvel (figure)', es: 'Marvel (figura)', de: 'Marvel (Abbildung)', fr: 'Marvel (figure)', pl: 'Marvel (rysunek)'},
      { cs: 'vědec', en: 'scientist', es: 'científico', de: 'Wissenschaftler', fr: 'scientifique', pl: 'naukowiec'},
    ]
  }
}

export default Config;