import * as actionTypes from './actions';

const initialState = {
  lang: "cs",
  canPlay: true,
  countdown: false,
  canCountdown: false,
  time: 80,
  currentTime: 80
}

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.SWITCH_LANG:
      return {
        ...state,
        lang: "en"
      }

    case actionTypes.GAME_START:
      return {
        ...state,
        canPlay: true
      }

    case actionTypes.GAME_STOP:
      return {
        ...state,
        canPlay: false
      }

    case actionTypes.COUNTDOWN_ENABLE:
      return {
        ...state,
        countdown: true
      }

    case actionTypes.COUNTDOWN_DISABLE:
      return {
        ...state,
        countdown: false
      }

    case actionTypes.COUNTDOWN_START:
      return {
        ...state,
        canCountdown: true
      }

    case actionTypes.COUNTDOWN_STOP:
      return {
        ...state,
        canCountdown: false
      }

    case actionTypes.COUNTDOWN_RESET:
      return {
        ...state,
        canCountdown: true,
        currentTime: state.time
      }

    case actionTypes.CURRENTTIME_MINUS:
      return {
        ...state,
        currentTime: state.currentTime - 1
      }

    default:
      return state;
  }
}

export default reducer;