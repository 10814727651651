import React, { Component } from 'react';
import { connect } from 'react-redux';
import Config from 'config/Config';
import './Char.scss';

class Char extends Component {
  state = {
    char: ''
  }

  generateChar = () => {
    const chars = Config.game.chars;
    let charRandomIndex = Math.floor(Math.random()* chars.length);
    let char = chars[charRandomIndex];

    this.setState({char});
  }

  onClickHandler = () => {
    if( this.props.canPlay ){
      this.generateChar();
    }
  }

  componentDidMount() {
    this.generateChar();
  }

  render() {
    return(
      <div className="Char-wrapper">
        <div className="Char gtm-char" onClick={this.onClickHandler}>{this.state.char}</div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    canPlay: state.canPlay
  }
}

export default connect(mapStateToProps)(Char);