import React from 'react';
import { connect } from 'react-redux';
import Helpers from 'shared/js/Helpers';
import './Filler.scss';

const Filler = (props) => {
  const percent = parseInt((props.currentTime / props.time) * 100);

  let fillerStyle = {
    width: percent + "%"
  }

  return(
    <div className="Filler" style={fillerStyle}>
      <div className="Filler__value">{Helpers.timeToStr(props.currentTime)}</div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    time: state.time,
    currentTime: state.currentTime
  }
}

export default connect(mapStateToProps)(Filler);
