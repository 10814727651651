const Helpers = {

  timeToStr(sec){
    var s = parseInt(sec % 60);
    var m = parseInt((sec / 60) % 60);
    var timeStr = "";

    if( sec <= 60 ){
      timeStr = sec + " sec.";
    }
    else{
      if (s < 10) { timeStr = m + ':0' + s; }
      else { timeStr = m + ':' + s; }
    }

    return timeStr;
  }

}

export default Helpers;