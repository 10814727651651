import React, { Component } from 'react';
import { connect } from 'react-redux';
import Config from 'config/Config';
import './Category.scss';

class Category extends Component {
  state = {
    category: ''
  }

  generateCategory = () => {
    const categories = Config.game.categories;
    const lang = this.props.lang;
    let categoryRandomIndex = Math.floor(Math.random()* categories.length);
    let category = categories[categoryRandomIndex][lang];

    this.setState({category});
  }

  onClickHandler = () => {
    if( this.props.canPlay ){
      this.generateCategory();
    }
  }

  componentDidMount() {
    this.generateCategory();
  }

  render() {
    return(
      <div className="Category-wrapper">
        <div className="Category gtm-Category" onClick={this.onClickHandler}>{this.state.category}</div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.lang,
    canPlay: state.canPlay
  }
}

export default connect(mapStateToProps)(Category);
