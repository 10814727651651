import React from 'react';
import { slide as BgMenu } from 'react-burger-menu';
import Langs from 'components/Langs/Langs';
import Copyright from 'components/Copyright/Copyright';
import './Menu.scss';

const Menu = (props) => {
  return(
      <BgMenu>
        <Langs />
        <Copyright />
      </BgMenu>
  )
}

export default Menu;